import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "500px",
      "persistent": ""
    },
    model: {
      value: _vm.duplicateFoundDialog,
      callback: function ($$v) {
        _vm.duplicateFoundDialog = $$v;
      },
      expression: "duplicateFoundDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Duplikatsvergleich")])], 1)], 1), _c(VCardText, [_c(VRow, {
    staticClass: "mt-5 text-h5"
  }, [_c(VCol, {
    attrs: {
      "cols": "7"
    }
  }, [_c('p', [_vm._v("Neue Adresse")])]), _c(VCol, {
    staticClass: "text-right",
    attrs: {
      "cols": "5"
    }
  }, [_c('p', [_vm._v("Vorhanden")])])], 1), _vm._l(_vm.address, function (val, key) {
    return _c('div', {
      key: key,
      attrs: {
        "fluid": ""
      }
    }, [_vm.address[key] == _vm.duplicate[key] ? _c(VRow, [_c(VCol, {
      attrs: {
        "cols": "5"
      }
    }, [_c('p', [_c('b', {
      staticClass: "button"
    }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(_vm.address[key]) + " ")])]), _c(VCol, {
      attrs: {
        "cols": "2"
      }
    }), _c(VCol, {
      staticClass: "text-right",
      attrs: {
        "cols": "5"
      }
    }, [_c('p', [_c('b', {
      staticClass: "button"
    }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(_vm.duplicate[key]) + " ")])])], 1) : _c(VRow, {
      staticClass: "font-weight-black primary--text align-center"
    }, [_c(VCol, {
      attrs: {
        "cols": "5"
      }
    }, [_c('p', [_c('b', {
      staticClass: "button"
    }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(_vm.address[key] != '' ? _vm.address[key] : '-') + " ")])]), _c(VCol, {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c(VChip, {
      staticClass: "mt-n3",
      attrs: {
        "color": "primary"
      }
    }, [_c(VIcon, [_vm._v("mdi-not-equal-variant")])], 1)], 1), _c(VCol, {
      staticClass: "text-right",
      attrs: {
        "cols": "5"
      }
    }, [_c('p', [_c('b', {
      staticClass: "button"
    }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(_vm.duplicate[key] != '' ? _vm.duplicate[key] : '-') + " ")])])], 1)], 1);
  })], 2), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };