export default {
  data: () => ({
    duplicateFoundDialog: false,
    address: null,
    duplicate: null
  }),
  methods: {
    open(newAddress, duplicate) {
      console.log(newAddress);
      this.address = newAddress;
      this.duplicate = duplicate;
      this.duplicateFoundDialog = true;
    },
    cancel() {
      this.duplicateFoundDialog = false;
    }
  }
};